<template>
  <div class="table">
    <el-row>
      <el-col :span="1">
        <div class="search_input">
          <el-button
            class="btn"
            icon="el-icon-plus"
            @click="onClick"
            type="primary"
            >更改</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="1">
        <div><img :src="url" alt="" /></div>
      </el-col>
    </el-row>
    <el-dialog
      title="更改图片"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <!-- <el-upload
        action="#"
        ref="upload"
        :limit="1"
        :class="{ disabled: uploadDisabled }"
        list-type="picture-card"
        :on-remove="handleRemove"
        :http-request="uploadSectionFile"
      >
        <i slot="default" class="el-icon-plus"></i>
      </el-upload> -->
      <!-- 图片裁剪弹框 -->
      <FileUpload
        :Name="cropperName"
        @uploadImgSuccess="handleUploadSuccess"
        ref="child"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="setImg">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FileUpload from "./upLode.vue";
export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      url: "",
      dialogVisible: false,
      uploadDisabled: false,
      img: "",
      cropperName: "",
      fileUpLoad: {
        width: 260,
        height: 309,
      },
    };
  },
  mounted() {
    this.getImg();
  },
  methods: {
    async getImg() {
      const res = await this.$post("/pass_check/get");
      //   console.log(res);
      if (res.flag) {
        this.url = res.data.url;
      }
    },
    async setImg() {
      const res = await this.$put("/pass_check/add", { url: this.img });
      console.log(res);
      if (res.flag) {
        this.dialogVisible = false;
        this.uploadDisabled = false;
        this.$refs.upload.clearFiles();
        this.getImg();
      }
    },
    // 图片上传
    // async uploadSectionFile(params) {
    //   const isJPG = params.file.type === "video/mp4";
    //   if (isJPG) {
    //     this.$message.error("只能上传图片格式!");
    //   } else {
    //     const fromData = new FormData();
    //     fromData.append("files", params.file);
    //     const res = await this.$post("/clue/upLoad", fromData);
    //     console.log(res);
    //     if (res.flag) {
    //       // this.Form.urlsBean.push(res.data[0]);
    //       this.img = res.data[0];
    //       this.uploadDisabled = true;
    //     }
    //   }
    // },
    // handleRemove() {
    //   this.uploadDisabled = false;
    //   this.$refs.upload.clearFiles();
    // },
     // 上传获取图片地址
    handleUploadSuccess(data) {
      this.getImg();
      console.log(data);
      this.dialogVisible = false;
    },
    onClick() {
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
        //   this.dialogVisible = false;
        //   this.uploadDisabled = true;
        //   this.$refs.upload.clearFiles();
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 16px;
  padding: 20px;
  img {
    width: 400px;
    height: 400px;
  }
  .disabled {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
}
</style>